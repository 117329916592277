import React, { useEffect, useState } from 'react';
import './ExploreJobs.scss';
import JobCard from '../JobCard';
import axios from 'axios';

const ExploreJobs = () => {
  const [loading, setLoading] = useState(false);
  const [newJobsData, setNewJobsData] = useState("");
  useEffect(() => {
    fetchNewJobs();
  }, []);

  const fetchNewJobs = async () => {
    {
      setLoading(true);
      const response = await axios.get('https://stage-apiplatform.finkraftai.com/api/meta/job/zoho/list');
      if (response.status) {
        setNewJobsData(response.data.data);
        console.log("response is:", response.data.data);

      }
      setLoading(false);
    };
  }

  const handleLearnMoreClick = () => {

  }

  return (
    <>
      <div className='explore-jobs-section'>
        <div className='job-header'>
          <div className='explore-job-title'>
            Careers you can grow
            <div style={{ marginTop: '-16px' }}>with</div>
          </div>
          <div className='explore-job-content'>
            <p>Check out our open roles to see where you can make an
              impact</p>
            <button className='explore-btn' onClick={() =>
              window.open(
                "https://finkraft.zohorecruit.com/jobs/Careers",
                "_blank"
              )
            }>View all positions  →</button>
          </div>
        </div>
        <div className="job-container">

          {loading ? (
            <div>Loading...</div>
          ) : (
            (newJobsData || []).map((job, index) => (
              <JobCard
                key={job.Job_Opening_ID || '0'}
                title={job.Posting_Title || "Job Title Not Available"}
                role={job.Job_Opening_Name || "Department Not Specified"}
                location="📍 In Office - Bengaluru "
                learnMoreLink={() => window.open(
                  `https://finkraft.zohorecruit.com/jobs/Careers/${job.id}`,
                  "_blank"
                )}
              />
            ))
          )}
        </div>
      </div>

    </>
  )
};

export default ExploreJobs;
