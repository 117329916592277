import logo from './logo.svg';
import LandingPage from './LandingPage';
// import AppRoutes from './routes/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <LandingPage />
        </header>
      </div>
    </Router>
  );
}

export default App;
