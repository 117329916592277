import React from 'react';
import './JobCard.scss';

const JobCard = ({ title, role, location, learnMoreLink }) => {
  console.log("job link:", learnMoreLink)
  return (
    <div className="job-card">
      <h3 className="job-title">{title}</h3>
      <p className="job-role">{role}</p>
      <p className="job-location">{location}</p>
      <a onClick={learnMoreLink} className="learn-more" style={{ cursor: 'pointer' }}>
        Learn more →
      </a>
    </div>
  );
};

export default JobCard;