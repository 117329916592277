// TestimonialCarousel.js
import React, { useState } from "react";
import "./Testimonials.scss";
import AmbujSingh from "../assets/AmbujSingh.png";
import KeyaShukla from "../assets/KeyaShukla.png";
import PriyaPathak from "../assets/PriyaPathak.png";
import MeenalSharma from "../assets/MeenalSharma.png";
import { LinkedinOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import QuotesImg from "../assets/quotes1.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Testimonial = () => {
  const testimonials = [
    {
      image: AmbujSingh,
      quote:
        "I do admire the company culture and, I like that. Finkraft is truly the place where good attitudes have blended with proficiency. If you are going to craft something great and you have a strong eagerness to do that, Finkraft will remove all hassles in your journey.",
      name: "Ambuj Singh",
      title: "VP (Sales)",
      linkedInProfile: "https://www.linkedin.com/in/ambuj-kumar-v-33b14311b/?originalSubdomain=in",
    },
    // Add more testimonial objects as needed
    {
      image: KeyaShukla,
      quote:
        "Finkraft isn’t just a company—it’s a place to grow, lead, and thrive. From day one, I’ve been empowered to make an impact, supported by inspiring teammates and leadership that values trust and collaboration. Here, hard work is recognised, ideas matter, and every voice counts.",
      name: "Keya Shukla",
      title: "VP (Product Management)",
      linkedInProfile: "https://www.linkedin.com/in/keya-shukla-kulkarni-1ab5395/",
    },
    {
      image: PriyaPathak,
      quote:
        "Being a software engineer at Finkraft has been an amazing journey! The work is impactful, tackling real-world challenges in GST compliance and corporate travel expenses. Every day brings new opportunities to innovate, collaborate with a talented team, and grow both personally and professionally.",
      name: "Priya Pathak",
      title: "Software Developer",
      linkedInProfile: "https://www.linkedin.com/in/priya-pathak-5182751aa/",
    },
    {
      image: MeenalSharma,
      quote:
        "Finkraft is more than just a workplace—it’s a launchpad for growth, innovation, and leadership. As part of the Founder’s Office, I’ve had the opportunity to work on high-impact projects, collaborate with visionary leaders, and contribute to shaping the company’s future.",
      name: "Meenal Sharma",
      title: "EA (Founder’s office)",
      linkedInProfile: "https://www.linkedin.com/in/meenalsharma17/",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex(
      (currentIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % testimonials.length);
  };

  return (
    <div className="testimonial-carousel">
      <h1 className="title">Don't just take our word for it!</h1>
      <div className="card-container">
        <Carousel
          showThumbs={false}
          showIndicators={true}
          autoPlay={true}
          infiniteLoop={true}
          // interval={5000}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carousel-arrow carousel-arrow-prev"
              >
                <LeftOutlined />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carousel-arrow carousel-arrow-next"
              >
                <RightOutlined />
              </button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className="indicator indicator-selected"
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className="indicator"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`card ${index === currentIndex ? "active" : ""}`}
            // style={{
            //   transform: `translateX(${(index - currentIndex) * 100}%)`,
            // }}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="card-image"
              />
              <div className="card-quote">
                {/* <img src={QuotesImg} alt="’" style={{ width: "80px" }} /> */}
                <div className="quotes">❞</div>
                <p>{testimonial.quote}</p>
                <cite>
                  <strong>{testimonial.name}</strong>
                  <div className="designation"> {testimonial.title} </div>

                  <a
                    href={testimonial.linkedInProfile}
                    className="card-linkedin-link"
                  >
                    <LinkedinOutlined style={{ fontSize: "21px" }} />
                    LinkedIn
                  </a>
                </cite>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {/* <div className="navigation">
        <button
          className="nav-button"
          onClick={handlePrevious}
          tyle={{ color: "gray", fontSize: "15px" }}
        >
          ← Scroll to previous
        </button>
        <span className="pagination">{`${currentIndex + 1} / ${
          testimonials.length
        }`}</span>
        <button
          className="nav-button"
          onClick={handleNext}
          style={{ color: "gray", fontSize: "15px" }}
        >
          Scroll to next →
        </button>
      </div> */}
    </div>
  );
};

export default Testimonial;
