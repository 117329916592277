import React, { useState } from "react";
import "./LandingPage.scss";
import Header from '../Header';
import HeroSection from "../HeroSection";
import CultureSection from "../CultureSection";
import Testimonial from "../Testimonials";
import Footer from "../Footer";
import ExploreJobs from "../ExploreJobs";


const LandingPage = () => {

  return (
    <header className="Page-container">
      <div className="header-container">
        <Header />
      </div>
      <div className="hero-section-container" >
        <HeroSection />
      </div>
      <div className="culture-section-container">
        <CultureSection />
      </div>
      <div className="testimonial-section-container">
        <Testimonial />
      </div>
      <div className="explorejobs-section-container">
        <ExploreJobs />
      </div>
      <div className="footer-section-container">
        <Footer />
      </div>
    </header>
  );
};

export default LandingPage;
