// Footer.js
import React from 'react';
import { Icon } from 'antd';
import { FacebookOutlined, LinkedinOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="nav-ul">
        < div className="nav-ul-items">
          <div className='vertical'>
            < a onClick={() => window.open("https://finkraft.ai/solutions/airline-&-hotel-gst-itc-automation.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }} >Solutions</ a>
            < a onClick={() => window.open("https://finkraft.ai/compliance/compliance-overview.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }} >Compliance</ a>
            < a onClick={() => window.open("https://finkraft.ai/resources/case-studies.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }} >Resources</ a>
            < a onClick={() => window.open("https://finkraft.ai/about/partners.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }} >About</ a>

          </div>

        </div>
        <div className="nav-ul-items">
          <div className='vertical'>
            < a onClick={() => window.open("https://finkraft.ai/get-in-touch.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }}>Get In Touch</ a>
            < a onClick={() => window.open("https://finkraft.ai/book-a-demo.html", "_blank")} className='footer-nav' style={{ cursor: 'pointer' }}>Book a Demo</ a>
          </div>
        </div>
      </nav>
      <div className="social-icons">
        <FacebookOutlined onClick={() => window.open("https://www.facebook.com/people/Finkraft/100069918660246/", "_blank")} className="icon" />
        <TwitterOutlined onClick={() => window.open("https://x.com/finkraft1?mx=2", "_blank")} className="icon" />
        <InstagramOutlined onClick={() => window.open("https://www.instagram.com/finkraft.ai/", "_blank")} className="icon" />
        < LinkedinOutlined onClick={() => window.open("https://www.linkedin.com/company/finkraft-ai/?originalSubdomain=in", "_blank")} className="icon" />
      </div>
      <div className='divider'></div>
      <div className="footer-content">
        <p className="copyright">© 2024 Finkraft.AI All Right Reserved</p>
        <div className="footer-links">
          <a onClick={() => window.open("https://finkraft.ai/terms-of-service.html", "_blank")} style={{ cursor: 'pointer' }} className="footer-link">
            Terms of Service
          </a>
          <a onClick={() => window.open("https://finkraft.ai/privacy-policy/index.html", "_blank")} style={{ cursor: 'pointer' }} className="footer-link">
            Privacy Policy
          </a>
          <a onClick={() => window.open("https://finkraft.ai/cookies-policy.html", "_blank")} style={{ cursor: 'pointer' }} className="footer-link">
            Cookie Policy
          </a>
          <a onClick={() => window.open("https://finkraft.ai/disclaimer.html", "_blank")} style={{ cursor: 'pointer' }} className="footer-link">
            Disclaimers
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;