// HeroSection.js
import React from "react";
import "./HeroSection.scss";
import HeroSectionBg from "../assets/Hero-section-bg.png";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <img src={HeroSectionBg} alt="Background" className="hero-bg" />
      <div className="hero-content">
        {/* <div className="hero-welcome-section">
          <div className="welcome">• Welcome</div>
          <div className="description">
            Collaborative & Innovative Workspace →
          </div>
        </div> */}
        <h1 className="hero-title">
          Shape the Future of Fintech
          <div>
            {" "}
            with <span style={{ color: "#0A8394" }}> Finkraft </span>{" "}
          </div>
        </h1>
        <p className="hero-description">
          At Finkraft, we're on a mission to revolutionise GST reconciliation,
          corporate finance, and compliance. Innovate, Collaborate, and Lead the
          Next Wave of Financial Solutions.
        </p>
        <div className="hero-btn-container">
          <button
            className="hero-button"
            onClick={() =>
              window.open(
                "https://finkraft.zohorecruit.com/jobs/Careers",
                "_blank"
              )
            }
          >
            See all open positions
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
