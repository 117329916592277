// WorkplaceBenefits.js
import React from 'react';
import './CultureSection.scss';
import CultureSection1 from '../assets/CultureSection1.png';
import CultureSection2 from '../assets/CultureSection2.png';
import CultureSection3 from '../assets/CultureSection3.png';
import CultureSection4 from '../assets/CultureSection4.png';

const WorkplaceBenefits = () => {
  const benefits = [
    {
      image: CultureSection1,
      title: 'Trusted by Top Clients',
      description: 'We work with some of the most respected names in the industry, giving you exposure to high-impact projects.'
    },
    {
      image: CultureSection2,
      title: 'Profitable & Growing Fast',
      description: 'Our consistent growth means more opportunities and long-term stability for you.'
    },
    {
      image: CultureSection3,
      title: 'Trust & Autonomy',
      description: 'We believe in empowering our team to make decisions and take ownership of their work.'
    },
    {
      image: CultureSection4,
      title: 'Rewards & Recognition',
      description: 'Your hard work is acknowledged with regular rewards & recognition that make you feel valued.'
    }
  ];

  return (
    <div className="workplace-benefits">
      <h1 className="title">What makes Finkraft a great <div style={{ marginTop: '-16px' }}>place to work?</div></h1>
      <div className="card-container">
        {benefits.map((benefit, index) => (
          <div key={index} className="card">
            <img src={benefit.image} alt={benefit.title} className="card-image" />
            <h3 className="card-title">{benefit.title}</h3>
            <p className="card-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkplaceBenefits;