import React, { useState, useEffect } from "react";
import "./Header.scss";
import FinkraftLogo from "../assets/FinkraftLogo.png";
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  // State to track mobile menu visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  // Effect to handle responsive design
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener('resize', checkMobile);

    // Clean up event listener
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Handler for mobile menu item click
  const handleMobileMenuClick = ({ key }) => {
    switch (key) {
      case 'about':
        window.open("https://finkraft.ai/", "_blank");
        break;
      case 'careers':
        navigate('/careers');
        break;
      case 'apply':
        window.open("https://finkraft.zohorecruit.com/jobs/Careers", "_blank");
        break;
      default:
        break;
    }
  };

  // Dropdown menu items
  const mobileMenuItems = [
    {
      key: 'about',
      label: 'About Us'
    },
    {
      key: 'careers',
      label: 'Careers'
    },
    {
      key: 'apply',
      label: 'Apply Now'
    }
  ];

  // Render method with conditional rendering based on screen size
  return (
    <header className="top-header">
      <div className="header-content">
        <div className="logo">
          <img src={FinkraftLogo} alt="Finkraft Logo" />
        </div>

        {isMobile ? (
          // Mobile view with dropdown
          <Dropdown
            overlay={
              <Menu
                items={mobileMenuItems}
                onClick={handleMobileMenuClick}
                className="mobile-dropdown-menu"
              />
            }
            trigger={['click']}
          >
            <MenuOutlined className="mobile-menu-icon" />
          </Dropdown>
        ) : (
          // Desktop view with normal buttons
          <div className="header-actions">
            <button
              className="action-btn"
              onClick={() => window.open("https://finkraft.ai/", "_blank")}
            >
              About Us
            </button>
            <button
              className="action-btn"
              onClick={() => navigate('/careers')}
            >
              Careers
            </button>
            <button
              className="action-btn apply-now-btn"
              onClick={() => window.open("https://finkraft.zohorecruit.com/jobs/Careers", "_blank")}
            >
              Apply Now
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;